@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.expand-contract {
  margin-top: -100px;
  transition: all 700ms;
}

.expand-contract.expanded {
  margin-top: 0px;
}

.no-scroll {
  overflow: hidden;
}


.phone-frame {
  width: 280px;
  height: 580px;
  background-color: #1a1a1a;
  border-radius: 40px;
  padding: 10px;
  box-shadow: 0 0 0 10px #1a1a1a, 0 0 0 11px #999, 0 20px 30px rgba(0, 0, 0, 0.3);
  position: relative;
}

.phone-screen {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 30px;
  overflow: hidden;
}

.phone-notch {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 30px;
  background-color: #1a1a1a;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}